//! @ngInject
export function
  importAgencyContractMissmatchModalCtrl (
    $rootScope,
    $scope,
    importAgencyService,
    $uibModalInstance,
    job,
    NgTableParams,
    stepId
  ) {
    $scope.textOption = "";
    $scope.textSelected = false;

    $scope.dropdownSettings = {
      styleActive: true,
      scrollable: true,
      enableSearch: true,
      showCheckAll: false,
      showUncheckAll: false,
      selectionLimit: 1,
      closeOnSelect: true,
      scrollableHeight: "250px",
    };
    
    $scope.isLoading = true;
    importAgencyService.getContractMissmatchIssues(job.id).then(function (res) {
      $scope.issues = res.data.issues;
      $scope.issues.forEach(issue => {
        issue.selectedOption = [];
        issue.resolutions =
        issue.resolutions.map((i, index) => ({id: index, label:i}))
      })
      $scope.initTable();
      $scope.isLoading = false;
    });

    $scope.initTable = () => {
      const tableOptions = {
        count: 10,
      };

      $scope.tableData = new NgTableParams(tableOptions, {
        counts: [],
        dataset: $scope.issues ? $scope.issues : [],
      });
    };

    $scope.selectOption = (item, option) => {
      const index = $scope.issues.indexOf(item);
     if ($scope.issues[index].selectedOption !== option) {
      $scope.issues[index].selectedOption = option;
      $scope.issues[index].resolutions.push(option);
     } else {
       $scope.issues[index].selectedOption = null;
       $scope.issues[index].resolutions.pop();
      }
    };

    $scope.exportTable = function () {
      $scope.loadingCSV = true;
      const rows = [];
      const titles = [
          'Issue',
          'Possible_resolutions',
          'selected_resolution',
      ];
      rows.push(titles);

      // Filter table data by global filter
      $scope.issues.forEach(dataRow => {
          const csvRow = [];

          csvRow.push('"' + dataRow.integrity_question.replace(/\n|,/g,".") + '"');
          csvRow.push('"' + dataRow.resolutions.join(", ") + '"');
          csvRow.push(dataRow.selectedOption ?? '');
          rows.push(csvRow);
      });

      let csvContent = "";
      rows.forEach(function (rowArray) {
        const row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      const universalBOM = "\uFEFF";
      const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", job.name + "-integrity-issues.csv");
      document.body.appendChild(link);

      link.click();
      $scope.loadingCSV = false;
  };

    $scope.saveResolutions = () => {
      $scope.isLoading = true;
      importAgencyService
        .postContractMissmatchIssues(
          job.id,
          $scope.issues.map((issue) => ({
            agency_import_job_missmatch_issue_id: issue.id,
            resolution: issue.selectedOption.label ? issue.selectedOption.label : issue.selectedOption,
          }))
        )
        .then(() => {
          importAgencyService
            .moveToNextStage(job.id, stepId, {
              name: "INTEGRITY_ISSUES",
              status: "SUCCESS",
              agency_import_job_id: job.id,
            })
            .then(() => {
              $uibModalInstance.close("ok");
            })
            .catch((e) => {
              $scope.isLoading = false;
              $scope.errors = e.data.error;
            });
        });
    };

    $scope.isSaveEnabled = () => {
      return $scope.issues
        ? $scope.issues.filter((issue) => issue.selectedOption).length === $scope.issues.length
        : false;
    };
  };
