//! @ngInject
export function importAgencySetupModal (
    $scope,
    toaster,
    importAgencyService,
    $uibModal,
    job,
    $window,
    stepId,
    $uibModalInstance,
    mfModal
  ) {
    $scope.setupSelection = false;
    $scope.selectedAgency = null;

    $scope.formModel = {
      name: "",
      website: "",
      officePhone: "",
      address: "",
      isSandboxImport: true
    };

    $scope.advancedSettings = {
      onlyCaregivers: false,
      onlyCompliance: false,
      skipWeeklyTemplateRollover: false,
      skipPatientAddresses: false,
      skipCaregiversAddresses: false,
      skipAgencyMembers: false,
      skipPhoneNumbers: false
    };

    importAgencyService.getAgencies().then((res) => {
      $scope.agencies = res.data.agencies;
      $scope.viewAgencies = res.data.agencies;
    });


    $scope.agencyAutoCompleteOptions = {
      minimumChars: 1,
      data: function () {
        return $scope.viewAgencies;
      },
      renderItem: function (item) {
        return {
          value: item.name,
          label: "<p class='auto-complete'>" + item.id + " - " + item.name + "</p>",
        };
      },
      itemSelected: function (e) {
        $scope.selectedAgency = e.item;
      },
    };

    $scope.selectAgency = (agency) => {
      $scope.selectedAgency === agency
        ? ($scope.selectedAgency = null)
        : ($scope.selectedAgency = agency);
    };

    $scope.search = (searchText) => {
      $scope.viewAgencies = $scope.agencies.filter(
        (agency) => agency.id.toString().includes(searchText) || agency.name.includes(searchText)
      );
    };

    $scope.isConfirmDisabled = () => {
      return (
        ($scope.setupSelection && !$scope.selectedAgency) ||
        (!$scope.setupSelection &&
          Object.values($scope.formModel).some((model) => model === null || model === ""))
      );
    };

    $scope.submit = () => {
      const modal = mfModal.create({
        subject: "The agency setup process is done!",
        message: `You are about to import the data into a ${
          $scope.formModel.isSandboxImport ? "Sandbox" : "Production"
        } environment, 
        for the agency: ${
          $scope.setupSelection ? $scope.selectedAgency.name : $scope.formModel.name
        }. 
        Please confirm in order to continue.`,
        hideCancelButton: false,
        confirmLabel: "Confirm",
        onConfirm: () => {
          let selectedAgency;
          if ($scope.setupSelection) {
            selectedAgency = $scope.selectedAgency;
          } else {
            $scope.formModel.officePhone = "+1" + $scope.formModel.officePhone;
            selectedAgency = $scope.formModel;
          }
          importAgencyService
            .setAgencyParams(job.id, selectedAgency, $scope.advancedSettings)
            .then(() => {
              importAgencyService
                .moveToNextStage(job.id, stepId, {
                  name: "GENERAL_SETUP",
                  status: "SUCCESS",
                  agency_import_job_id: job.id,
                })
                .then(() => {
                  $uibModalInstance.close("ok");
                })
                .catch((e) => {
                  $scope.errors = e.data.error;
                });
            })
            .catch((e) => {
              toaster.pop("error", e.data.details.toString());
            })
            .finally(() => {
              modal.close();
            });
        },
      });
    };
  };
