import _ from "lodash";

//! @ngInject
export function importAgencyServiceCodeMergeCtrl ($scope, importAgencyService, job, stepId, $uibModalInstance) {
    $scope.unmergedServiceCodesSet = new Set();
    $scope.showOnlyActive = true;

    importAgencyService.getServiceCodeMergesIssues(job.id).then((res) => {
      $scope.issues = res.data.issues;
      $scope.filterActiveMerges();
    });

    $scope.unmergeServiceCode = (oldServiceCodeId) => {
      $scope.unmergedServiceCodesSet.has(oldServiceCodeId)
        ? $scope.unmergedServiceCodesSet.delete(oldServiceCodeId)
        : $scope.unmergedServiceCodesSet.add(oldServiceCodeId);
    };

    $scope.filterActiveMerges = () => {
      if ($scope.showOnlyActive) {
        $scope.groupedIssues = _.groupBy(
          $scope.issues.filter((issue) => issue.status === "Active"),
          "oldServiceCode"
        );
      } else {
        $scope.groupedIssues = _.groupBy($scope.issues, "oldServiceCode");
      }
    };

    $scope.exportTable = function () {
      $scope.loadingCSV = true;
      const rows = [];
      const titles = [
          'old_service_code',
          'contract',
          'new_service_code',
          'status',
          'export_code',
          'revenue_code',
          'rates_type',
          'certification',
          'is_mutual',
          'is_overlap_allowed',
      ];
      rows.push(titles);

      // Filter table data by global filter
      $scope.issues.forEach(dataRow => {
          const csvRow = [];

          csvRow.push(dataRow.oldServiceCode.replace(/\n|,/g," "));
          csvRow.push(dataRow.contract.replace(/\n|,/g," "));
          csvRow.push(dataRow.newServiceCode.replace(/\n|,/g," "));
          csvRow.push(dataRow.status);
          csvRow.push(dataRow.exportCode);
          csvRow.push(dataRow.revenueCode);
          csvRow.push(dataRow.ratesType);
          csvRow.push(dataRow.certification);
          csvRow.push(dataRow.mutual);
          csvRow.push(dataRow.allowOverlap);
          rows.push(csvRow);
      });

      let csvContent = "";
      rows.forEach(function (rowArray) {
        const row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      const universalBOM = "\uFEFF";
      const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", job.name + "-svc-merges-issues.csv");
      document.body.appendChild(link);

      link.click();
      $scope.loadingCSV = false;
  };

    $scope.moveToNextStage = () => {
      const resolutions = Array.from($scope.unmergedServiceCodesSet, (resolution) => ({
        unmergedServiceCodeId: resolution,
      }));

      importAgencyService.resolveServiceCodeMerges(job.id, resolutions).then(() => {
        importAgencyService
          .moveToNextStage(job.id, stepId, {
            name: "MERGE_SERVICE_CODES_SETUP",
            status: "SUCCESS",
            agency_import_job_id: job.id,
          })
          .then(() => {
            $uibModalInstance.close("ok");
          })
          .catch((e) => {
            $scope.errors = e.data.error;
          });
      });
    };
  };
