//! @ngInject
export function importAgencyLocationGroupModalCtrl (
    $rootScope,
    $scope,
    importAgencyService,
    $uibModalInstance,
    job,
    NgTableParams,
    stepId
  ) {
    $scope.tableData = [];

    $scope.tableData.push(
      {
        groupName: "Long island",
        state: "NY",
        counties: [
          { id: 1, label: "Nassau County" },
          { id: 61, label: "Suffolk County" },
          { id: 62, label: "Westchester County" },
          { id: 2, label: "Albany County" },
          { id: 3, label: "Allegany County" },
          { id: 4, label: "Broome County" },
          { id: 5, label: "Cattaraugus County" },
          { id: 6, label: "Cayuga County" },
          { id: 7, label: "Chautauqua County" },
          { id: 8, label: "Chemung County" },
          { id: 9, label: "Chenango County" },
          { id: 10, label: "Clinton County" },
          { id: 11, label: "Columbia County" },
          { id: 12, label: "Cortland County" },
          { id: 13, label: "Delaware County" },
          { id: 14, label: "Dutchess County" },
          { id: 15, label: "Erie County" },
          { id: 16, label: "Essex County" },
          { id: 17, label: "Franklin County" },
          { id: 18, label: "Fulton County" },
          { id: 19, label: "Genesee County" },
          { id: 20, label: "Greene County" },
          { id: 21, label: "Hamilton County" },
          { id: 22, label: "Herkimer County" },
          { id: 23, label: "Jefferson County" },
          { id: 24, label: "Lewis County" },
          { id: 25, label: "Livingston County" },
          { id: 26, label: "Madison County" },
          { id: 27, label: "Monroe County" },
          { id: 28, label: "Montgomery County" },
          { id: 29, label: "Niagara County" },
          { id: 30, label: "Oneida County" },
          { id: 31, label: "Onondaga County" },
          { id: 32, label: "Ontario County" },
          { id: 33, label: "Orange County" },
          { id: 34, label: "Orleans County" },
          { id: 35, label: "Oswego County" },
          { id: 36, label: "Otsego County" },
          { id: 37, label: "Putnam County" },
          { id: 38, label: "Rensselaer County" },
          { id: 39, label: "Rockland County" },
          { id: 40, label: "Saint Lawrence County" },
          { id: 41, label: "Saratoga County" },
          { id: 42, label: "Schenectady County" },
          { id: 43, label: "Schoharie County" },
          { id: 44, label: "Schuyler County" },
          { id: 45, label: "Seneca County" },
          { id: 46, label: "Steuben County" },
          { id: 47, label: "Sullivan County" },
          { id: 48, label: "Tioga County" },
          { id: 49, label: "Tompkins County" },
          { id: 50, label: "Ulster County" },
          { id: 51, label: "Warren County" },
          { id: 52, label: "Washington County" },
          { id: 53, label: "Wayne County" },
          { id: 54, label: "Wyoming County" },
          { id: 55, label: "Yates County" },
          { id: 56, label: "Bronx County" },
          { id: 57, label: "Queens County" },
          { id: 58, label: "Kings County" },
          { id: 59, label: "Richmond County" },
          { id: 60, label: "New York County" },
        ],
        selectedCounties: [
          { id: 1, label: "Nassau County" },
          { id: 61, label: "Suffolk County" },
          { id: 62, label: "Westchester County" },
        ],
      },
      {
        groupName: "New York",
        state: "NY",
        counties: [
          { id: 1, label: "Nassau County" },
          { id: 61, label: "Suffolk County" },
          { id: 62, label: "Westchester County" },
          { id: 2, label: "Albany County" },
          { id: 3, label: "Allegany County" },
          { id: 4, label: "Broome County" },
          { id: 5, label: "Cattaraugus County" },
          { id: 6, label: "Cayuga County" },
          { id: 7, label: "Chautauqua County" },
          { id: 8, label: "Chemung County" },
          { id: 9, label: "Chenango County" },
          { id: 10, label: "Clinton County" },
          { id: 11, label: "Columbia County" },
          { id: 12, label: "Cortland County" },
          { id: 13, label: "Delaware County" },
          { id: 14, label: "Dutchess County" },
          { id: 15, label: "Erie County" },
          { id: 16, label: "Essex County" },
          { id: 17, label: "Franklin County" },
          { id: 18, label: "Fulton County" },
          { id: 19, label: "Genesee County" },
          { id: 20, label: "Greene County" },
          { id: 21, label: "Hamilton County" },
          { id: 22, label: "Herkimer County" },
          { id: 23, label: "Jefferson County" },
          { id: 24, label: "Lewis County" },
          { id: 25, label: "Livingston County" },
          { id: 26, label: "Madison County" },
          { id: 27, label: "Monroe County" },
          { id: 28, label: "Montgomery County" },
          { id: 29, label: "Niagara County" },
          { id: 30, label: "Oneida County" },
          { id: 31, label: "Onondaga County" },
          { id: 32, label: "Ontario County" },
          { id: 33, label: "Orange County" },
          { id: 34, label: "Orleans County" },
          { id: 35, label: "Oswego County" },
          { id: 36, label: "Otsego County" },
          { id: 37, label: "Putnam County" },
          { id: 38, label: "Rensselaer County" },
          { id: 39, label: "Rockland County" },
          { id: 40, label: "Saint Lawrence County" },
          { id: 41, label: "Saratoga County" },
          { id: 42, label: "Schenectady County" },
          { id: 43, label: "Schoharie County" },
          { id: 44, label: "Schuyler County" },
          { id: 45, label: "Seneca County" },
          { id: 46, label: "Steuben County" },
          { id: 47, label: "Sullivan County" },
          { id: 48, label: "Tioga County" },
          { id: 49, label: "Tompkins County" },
          { id: 50, label: "Ulster County" },
          { id: 51, label: "Warren County" },
          { id: 52, label: "Washington County" },
          { id: 53, label: "Wayne County" },
          { id: 54, label: "Wyoming County" },
          { id: 55, label: "Yates County" },
          { id: 56, label: "Bronx County" },
          { id: 57, label: "Queens County" },
          { id: 58, label: "Kings County" },
          { id: 59, label: "Richmond County" },
          { id: 60, label: "New York County" },
        ],
        selectedCounties: [
          { id: 56, label: "Bronx County" },
          { id: 57, label: "Queens County" },
          { id: 58, label: "Kings County" },
          { id: 59, label: "Richmond County" },
          { id: 60, label: "New York County" },
        ],
      },
      {
        groupName: "Upstate",
        state: "NY",
        counties: [
          { id: 1, label: "Nassau County" },
          { id: 61, label: "Suffolk County" },
          { id: 62, label: "Westchester County" },
          { id: 2, label: "Albany County" },
          { id: 3, label: "Allegany County" },
          { id: 4, label: "Broome County" },
          { id: 5, label: "Cattaraugus County" },
          { id: 6, label: "Cayuga County" },
          { id: 7, label: "Chautauqua County" },
          { id: 8, label: "Chemung County" },
          { id: 9, label: "Chenango County" },
          { id: 10, label: "Clinton County" },
          { id: 11, label: "Columbia County" },
          { id: 12, label: "Cortland County" },
          { id: 13, label: "Delaware County" },
          { id: 14, label: "Dutchess County" },
          { id: 15, label: "Erie County" },
          { id: 16, label: "Essex County" },
          { id: 17, label: "Franklin County" },
          { id: 18, label: "Fulton County" },
          { id: 19, label: "Genesee County" },
          { id: 20, label: "Greene County" },
          { id: 21, label: "Hamilton County" },
          { id: 22, label: "Herkimer County" },
          { id: 23, label: "Jefferson County" },
          { id: 24, label: "Lewis County" },
          { id: 25, label: "Livingston County" },
          { id: 26, label: "Madison County" },
          { id: 27, label: "Monroe County" },
          { id: 28, label: "Montgomery County" },
          { id: 29, label: "Niagara County" },
          { id: 30, label: "Oneida County" },
          { id: 31, label: "Onondaga County" },
          { id: 32, label: "Ontario County" },
          { id: 33, label: "Orange County" },
          { id: 34, label: "Orleans County" },
          { id: 35, label: "Oswego County" },
          { id: 36, label: "Otsego County" },
          { id: 37, label: "Putnam County" },
          { id: 38, label: "Rensselaer County" },
          { id: 39, label: "Rockland County" },
          { id: 40, label: "Saint Lawrence County" },
          { id: 41, label: "Saratoga County" },
          { id: 42, label: "Schenectady County" },
          { id: 43, label: "Schoharie County" },
          { id: 44, label: "Schuyler County" },
          { id: 45, label: "Seneca County" },
          { id: 46, label: "Steuben County" },
          { id: 47, label: "Sullivan County" },
          { id: 48, label: "Tioga County" },
          { id: 49, label: "Tompkins County" },
          { id: 50, label: "Ulster County" },
          { id: 51, label: "Warren County" },
          { id: 52, label: "Washington County" },
          { id: 53, label: "Wayne County" },
          { id: 54, label: "Wyoming County" },
          { id: 55, label: "Yates County" },
          { id: 56, label: "Bronx County" },
          { id: 57, label: "Queens County" },
          { id: 58, label: "Kings County" },
          { id: 59, label: "Richmond County" },
          { id: 60, label: "New York County" },
        ],
        selectedCounties: [
          { id: 2, label: "Albany County" },
          { id: 3, label: "Allegany County" },
          { id: 4, label: "Broome County" },
          { id: 5, label: "Cattaraugus County" },
          { id: 6, label: "Cayuga County" },
          { id: 7, label: "Chautauqua County" },
          { id: 8, label: "Chemung County" },
          { id: 9, label: "Chenango County" },
          { id: 10, label: "Clinton County" },
          { id: 11, label: "Columbia County" },
          { id: 12, label: "Cortland County" },
          { id: 13, label: "Delaware County" },
          { id: 14, label: "Dutchess County" },
          { id: 15, label: "Erie County" },
          { id: 16, label: "Essex County" },
          { id: 17, label: "Franklin County" },
          { id: 18, label: "Fulton County" },
          { id: 19, label: "Genesee County" },
          { id: 20, label: "Greene County" },
          { id: 21, label: "Hamilton County" },
          { id: 22, label: "Herkimer County" },
          { id: 23, label: "Jefferson County" },
          { id: 24, label: "Lewis County" },
          { id: 25, label: "Livingston County" },
          { id: 26, label: "Madison County" },
          { id: 27, label: "Monroe County" },
          { id: 28, label: "Montgomery County" },
          { id: 29, label: "Niagara County" },
          { id: 30, label: "Oneida County" },
          { id: 31, label: "Onondaga County" },
          { id: 32, label: "Ontario County" },
          { id: 33, label: "Orange County" },
          { id: 34, label: "Orleans County" },
          { id: 35, label: "Oswego County" },
          { id: 36, label: "Otsego County" },
          { id: 37, label: "Putnam County" },
          { id: 38, label: "Rensselaer County" },
          { id: 39, label: "Rockland County" },
          { id: 40, label: "Saint Lawrence County" },
          { id: 41, label: "Saratoga County" },
          { id: 42, label: "Schenectady County" },
          { id: 43, label: "Schoharie County" },
          { id: 44, label: "Schuyler County" },
          { id: 45, label: "Seneca County" },
          { id: 46, label: "Steuben County" },
          { id: 47, label: "Sullivan County" },
          { id: 48, label: "Tioga County" },
          { id: 49, label: "Tompkins County" },
          { id: 50, label: "Ulster County" },
          { id: 51, label: "Warren County" },
          { id: 52, label: "Washington County" },
          { id: 53, label: "Wayne County" },
          { id: 54, label: "Wyoming County" },
          { id: 55, label: "Yates County" },
        ],
      }
    );

    $scope.initTable = () => {
      const tableOptions = {
        count: 10,
      };

      $scope.tableData = new NgTableParams(tableOptions, {
        counts: [],
        dataset: $scope.tableData.length > 0 ? $scope.tableData : [],
      });
    };

    importAgencyService.getCounties().then(function (res) {
      $scope.countiesMap = res.data.counties.reduce((map, d) => {
        const value = { id: d.id, label: d.name };
        if (!map.has(d.state)) {
          map.set(d.state, [value]);
        } else {
          map.get(d.state).push(value);
        }
        return map;
      }, new Map());
      $scope.states = [...$scope.countiesMap.keys()];
    });

    $scope.dropdownSettings = {
      styleActive: true,
      scrollable: true,
      scrollableHeight: "250px",
      enableSearch: true,
    };
    $scope.initTable();

    $scope.createNewLocationGroup = () => {
      $scope.tableData.data.push({
        groupName: "",
        state: null,
        counties: [],
        selectedCounties: [],
      });
    };

    $scope.removeGroup = (index) => {
      $scope.tableData.data.splice(index, 1);
    };

    $scope.onStateSelected = (index, state) => {
      $scope.tableData.data[index].counties = $scope.countiesMap.get(state);
      $scope.tableData.data[index].selectedCounties = [];
    };

    $scope.saveLocationGroups = () => {
      const locationGroups = $scope.tableData.data.map((locationGroup) => ({
        name: locationGroup.groupName,
        state: locationGroup.state,
        counties: locationGroup.selectedCounties.map((county) =>
          county.label
            ? county.label
            : [...$scope.countiesMap.values()]
                .flat()
                .find((countyMap) => countyMap.id === county.id).label
        ),
      }));
      importAgencyService.saveLocationGroups(job.id, locationGroups).then( () => {
        importAgencyService.moveToNextStage(job.id, stepId,{name: "COUNTIES_SETUP",status: "SUCCESS",agency_import_job_id: job.id}).then(() =>{
          $uibModalInstance.close("ok");
        }).catch((e) => {
            $scope.errors = e.data.error;
        });
      });
    };
  };
