//! @ngInject
export function importAgencyUploadMissingFilesModalCtrl (
    $scope,
    $uibModalInstance,
    job,
    $uibModal,
    toaster,
    NgTableParams,
    missingFiles,
    importAgencyService
  ) {
    $scope.missingFiles = missingFiles;
    $scope.uploadedSet = new Set();

    $scope.initTable = () => {
      const tableOptions = {
        count: 10,
      };

      $scope.tableData = new NgTableParams(tableOptions, {
        counts: [],
        dataset: $scope.missingFiles,
      });
    };

    $scope.uploadFile = (file) => {
      const uploadModal = $uibModal.open({
        templateUrl: "admin/views/upload-file-modal.html",
        size: "md",
        controller: "uploadFileModalCtrl",
        resolve: {
          job: () => {
            return job;
          },
          fileId: () => {
            return null;
          },
          reportType: () => {
            return file.type;
          },
        },
      });
      uploadModal.result.then((res) => {
        if (res === "ok") {
          $scope.uploadedSet.add(file);
          if (Array.from($scope.uploadedSet).length === $scope.missingFiles.length) {
            $scope.closeModal("ok");
          }
        }
      });
    };

    $scope.downloadTemplate = (file) => {
      importAgencyService
        .getFileTemplateColumns(file.type)
        .then((template) => {
          const rows = [];
          rows.push(template.data.template[0].columns);

          let csvContent = "";
          rows.forEach(function (rowArray) {
            rowArray = rowArray.map((row) => {
              switch (row) {
                case "Emergency  Contact2 Name": {
                  return "Emergency Contact2 Name";
                }
                case "OTPayRate": {
                  return "OT Pay Rate";
                }
                case "HolidayPayRate": {
                  return "Holiday Pay Rate";
                }
                case "MedForm": {
                  return "Med Form";
                }
                case "MedRoute": {
                  return "Med Route";
                }
                case "MedStatus": {
                  return "Med Status";
                }
                case "SuspensionDate": {
                  return "Suspension Date";
                }
                case "RevokeDate": {
                  return "Revoke Date";
                }
              }
              let humanize = /[a-z][A-Z]\w/g.exec(row);
              if (humanize) {
                return row.split(humanize[0].slice(1)).join(" " + humanize[0].slice(1));
              }
              return row;
            });
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
          });

          const universalBOM = "\uFEFF";
          const encodedUri =
            "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
          const link = document.createElement("a");
          link.setAttribute("href", encodedUri);
          link.setAttribute("download", file.filename);
          document.body.appendChild(link);

          link.click();
        })
        .catch((error) => {
          toaster.pop("error", "Something went wrong", "Couldn't download file template");
        });
    };

    $scope.closeModal = (res) => {
      $uibModalInstance.close(res);
    };
    $scope.initTable();
  };
