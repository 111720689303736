//! @ngInject
export function importAgencySelectLocationGroupModalCtrl (
    $scope,
    importAgencyService,
    job,
    contractName,
    $uibModalInstance
  ) {
    $scope.contractName = contractName;
    $scope.selectedGroups = [];

    $scope.dropdownSettings = {
      styleActive: true,
      scrollable: true,
      scrollableHeight: "250px",
      enableSearch: true,
    };

    importAgencyService.getJobLocationGroups(job.id).then((locationGroups) => {
      $scope.locationGroups = locationGroups.data.locationGroups;

      $scope.locationGroupNames = $scope.locationGroups.filter(
        (value, index, self) => index === self.findIndex((t) => t.name === value.name)
      );

      $scope.locationGroupNames = $scope.locationGroupNames.map((group) => ({
        id: group.id,
        label: group.name,
      }));
    });

    $scope.onLocationsSelected = () => {
      const selectedNames = $scope.locationGroupNames.filter(group => $scope.selectedGroups.some(item => item.id === group.id));
      const selectedCounties = $scope.locationGroups.filter(county => selectedNames.some(item => item.label === county.name));
        $scope.closeModal(selectedCounties.map(county => county.id),selectedNames.map(group => group.label));
    };

    $scope.closeModal = (selectedCounties, selectedGroups) => {
        $uibModalInstance.close({selectedCounties: selectedCounties, selectedGroups: selectedGroups});
    };
  };
