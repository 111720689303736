//! @ngInject
export function importAgencyInactivatePocModalCtrl ($rootScope, $scope, selectedItem, itemsList, $uibModalInstance) {
    $scope.selectedItem = selectedItem;
    $scope.itemsList = itemsList.filter((item) => item !== selectedItem);

    $scope.setNewItem = (newItem) => {
      if (newItem) {
        $scope.selectedItem.replacedCode = newItem.code;
        $scope.selectedItem.replacedName = newItem.name;
        $scope.selectedItem.isActive = false;
        $scope.selectedItem.inactivatedManually = true;
        $uibModalInstance.close($scope.selectedItem);
      }
    };

    $scope.inactivateItem = () => {
      $scope.selectedItem.isActive = false;
      $scope.selectedItem.duplicate = false;
      $scope.selectedItem.inactivatedManually = true;
      $uibModalInstance.close($scope.selectedItem);
    };
  };
