//! @ngInject
export function importAgencyService($http, wildcard, DatabaseApi, Consts, $window, toaster) {
  return {
    getAgenciesImports: () => {
      let url = "agency_import_jobs";

      return DatabaseApi.get(url);
    },
    getAgenciesImportsSteps: () => {
      let url = "agency_import_jobs/steps";
      return DatabaseApi.get(url);
    },
    createNewAgencyImport: (formData) => {
      let url = "agency_import_jobs";

      var body = {
        name: formData.agencyName,
        hhaex_user: formData.userName,
        hhaex_pass: formData.password,
        hhaex_visit_from_date: formData.importFromDate,
        notes: null,
        agency_setup_admin_user_email: null,
        agency_setup_address: null,
        agency_setup_website: null,
        agency_setup_phone_number: null,
        setup_params: null,
        agency_setup_agency_name: formData.agencyName,
        removed_at: null,
        prod_agency_id: -1,
      };

      return DatabaseApi.post(url, body);
    },
    getAgencyImportFiles: (id) => {
      let url = wildcard("agency_import_jobs/:id/reports", id);
      return DatabaseApi.get(url);
    },
    uploadAgencyImportFile: (file, id, fileId, reportType) => {
      var formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("key", id);
      formData.append("fileId", fileId);
      formData.append("reportType", reportType);

      let url = Consts.api + "agency_import_jobs/" + id + "/reports";
      return $http({
        url: url,
        method: "POST",
        data: formData,
        headers: { "Content-Type": undefined },
      });
    },
    downloadImportFile: (id, fileKey) => {
      let endpoint = wildcard(`agency_import_jobs/:id/file`, id);
      endpoint = `${endpoint}?fileKey=${fileKey}`;

      return DatabaseApi.get(endpoint);
    },
    moveToNextStage: (Jobid, stepId, stepData) => {
      let url = "agency_import_jobs/:agency_import_job_id/step/:id/next";
      return DatabaseApi.post(wildcard(url, Jobid, stepId), stepData);
    },
    getContractMissmatchIssues: (id) => {
      let url = "agency_import_jobs/:id/contract_missmatch";
      return DatabaseApi.get(wildcard(url, id));
    },
    postContractMissmatchIssues: (id, resolutions) => {
      let url = "agency_import_jobs/:id/contract_missmatch";

      var body = {
        resolutions: resolutions,
      };

      return DatabaseApi.post(wildcard(url, id), body);
    },
    getMissingRates: (id) => {
      let url = "agency_import_jobs/:id/rate_missmatch";
      return DatabaseApi.get(wildcard(url, id));
    },
    getCounties: () => {
      let url = "agency_import_jobs/locations/counties";
      return DatabaseApi.get(url);
    },
    saveLocationGroups: (jobId, locationGroups) => {
      let url = "agency_import_jobs/:id/county_groups";

      var body = {
        locationGroups: locationGroups,
      };

      return DatabaseApi.post(wildcard(url, jobId), body);
    },
    getAllFiles: (job) => {
      let url = "agency_import_jobs/:id/files";
      return DatabaseApi.get(wildcard(url, job.id));
    },
    getOverlappingRates: (id) => {
      let url = "agency_import_jobs/:id/overlapping_billing_rates";
      return DatabaseApi.get(wildcard(url, id));
    },
    resolveOverlappingRates: (id, resolutions) => {
      let url = "agency_import_jobs/:id/overlapping_billing_rates";

      var body = {
        resolutions: resolutions,
      };

      return DatabaseApi.post(wildcard(url, id), body);
    },
    getJobLocationGroups: (id) => {
      let url = "agency_import_jobs/:id/county_groups";
      return DatabaseApi.get(wildcard(url, id));
    },
    getServiceCodeMergesIssues: (id) => {
      let url = "agency_import_jobs/:id/service_code_merges";
      return DatabaseApi.get(wildcard(url, id));
    },
    resolveServiceCodeMerges: (id, resolutions) => {
      let url = "agency_import_jobs/:id/service_code_merges";

      var body = {
        resolutions: resolutions,
      };

      return DatabaseApi.post(wildcard(url, id), body);
    },
    getComplianceData: (id) => {
      let url = "agency_import_jobs/:id/compliance";
      return DatabaseApi.get(wildcard(url, id));
    },
    getAgencies: () => {
      let url = "agency_import_jobs/agencies";
      return DatabaseApi.get(url);
    },
    setAgencyParams: (id, agencyData, advancedSettings) => {
      let url = "agency_import_jobs/:id";

      var body = {
        agency_setup_agency_name: agencyData.name,
        agency_setup_website: agencyData.website,
        agency_setup_phone_number: agencyData.officePhone,
        agency_setup_address: agencyData.address.formatted_address ?? "",
        agency_setup_params: advancedSettings,
      };

      return DatabaseApi.patch(wildcard(url, id), body);
    },
    getPocIssues: (id) => {
      let url = "agency_import_jobs/:id/plan_of_care";
      return DatabaseApi.get(wildcard(url, id));
    },
    resolvePoc: (id, items) => {
      let url = "agency_import_jobs/:id/plan_of_care";

      var body = {
        items: items,
      };

      return DatabaseApi.post(wildcard(url, id), body);
    },
    resolveCompliance: (id, resolutions) => {
      let url = "agency_import_jobs/:id/compliance";

      var body = {
        resolutions: resolutions,
      };

      return DatabaseApi.post(wildcard(url, id), body);
    },
    abortJob: (job, stepId) => {
      let url = "agency_import_jobs/:id/abort";

      var body = {
        stepId: stepId,
        executionArn: job.executionArn,
      };

      return DatabaseApi.post(wildcard(url, job.id), body);
    },
    getFileTemplateColumns: (fileType) => {
      let url = "agency_import_jobs/:fileType/template";

      return DatabaseApi.get(wildcard(url, fileType));
    },
    uploadPocMasterFile: (id, file) => {
      var formData = new FormData();
      formData.append("file", file, file.name);

      let url = Consts.api + "agency_import_jobs/" + id + "/poc";
      return $http({
        url: url,
        method: "POST",
        data: formData,
        headers: { "Content-Type": undefined },
      });
    },
  };
}
