import JSZip from "jszip";

//! @ngInject
export function importAgencyCtrl($scope, toaster, importAgencyService, $uibModal, $window) {
  $scope.steps = [];
  $scope.isLoading = true;
  $scope.getAgenciesImportData = () => {
    importAgencyService.getAgenciesImports().then(function (res) {
      $scope.jobs = res.data.agency_import_jobs;
      $scope.isLoading = false;
    }).catch(function (err) {
      toaster.pop("error",'Could not get imports');
    });
    $scope.getSteps();
  };

  $scope.getSteps = () => {
    importAgencyService.getAgenciesImportsSteps().then(function (res) {
      $scope.steps = res.data.steps;
    });
  };

  $scope.getAgenciesImportData();
  $scope.getSteps();
  setInterval(async () => {
    await $scope.getSteps() 
}, 10000);

  // Promise for intervals between + only active

  $scope.importNewAgency = () => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/import-agency-modal.html",
      size: "md",
      controller: "importNewAgencyModalCtrl",
      windowClass: "center-center",
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        $scope.getAgenciesImportData();
      }
    });
  };

  $scope.openFilesEditModal = (row) => {
    const step = $scope.steps.find(
      (step) => step.agency_import_job_id === row.id && step.name === "MANUAL_FILE_IMPORT"
    );
    if (step && step["id"]) {
      console.log(step);
      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/agency-import/import-agency-files-modal.html",
        size: "md",
        controller: "importAgencyFilesModalCtrl",
        windowClass: "center-center",
        resolve: {
          job: () => {
            return row;
          },
          stepId: step.id
        },
      });

      modalInstance.result.then((res) => {
        if (res === "ok") {
          row.stepStatus = 'Loading...';
          row.stepName = 'Loading...';
        }
      });
    } else {
      alert("Step not found");
    }
  };


  $scope.openContractMissmatchModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/import-agency-contract-missmatch-modal.html",
      size: "md",
      controller: "importAgencyContractMissmatchModalCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        }
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openMissingRatesModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/import-agency-missing-rates-modal.html",
      size: "md",
      controller: "importAgencyMissingRatesModalCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openLocationGroupModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/import-agency-location-group-modal.html",
      size: "md",
      controller: "importAgencyLocationGroupModalCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openOverlappingRatesModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/import-agency-overlapping-rates-modal.html",
      size: "md",
      controller: "importAgencyOvelappingRatesModalCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openServiceCodeMergeModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/agency-import-service-codes-merge-modal.html",
      size: "md",
      controller: "importAgencyServiceCodeMergeCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openComplianceModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/agency-import-compliance-modal.html",
      size: "md",
      controller: "importAgencyComplianceModalCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openAgencySetupModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/agency-import-setup-modal.html",
      size: "md",
      controller: "importAgencySetupModal",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.openPOCModal = (row) => {
    const modalInstance = $uibModal.open({
      templateUrl: "admin/views/agency-import/agency-import-poc-modal.html",
      size: "md",
      controller: "importAgencyPocModalCtrl",
      windowClass: "agency-import-modal center-center",
      resolve: {
        job: () => {
          return row;
        },
        stepId: () => {
          return $scope.steps.filter((step) => step.agency_import_job_id === row.id)[0]?.id;
        },
      },
    });

    modalInstance.result.then((res) => {
      if (res === "ok") {
        row.stepStatus = 'Loading...';
        row.stepName = 'Loading...';
      }
    });
  };

  $scope.stepsModalFunctions = {
    MANUAL_FILE_IMPORT: {function:$scope.openFilesEditModal, status:"RUNNING"},
    POC_ISSUES: {function:$scope.openPOCModal, status:"RUNNING"},
    INTEGRITY_ISSUES: {function:$scope.openContractMissmatchModal, status:"RUNNING"},
    RATES_SETUP: {function:$scope.openMissingRatesModal, status:"RUNNING"},
    COUNTIES_SETUP: {function:$scope.openLocationGroupModal, status:"RUNNING"},
    OVERLAPPING_RATES_SETUP: {function:$scope.openOverlappingRatesModal, status:"RUNNING"},
    MERGE_SERVICE_CODES_SETUP: {function:$scope.openServiceCodeMergeModal, status:"RUNNING"},
    GENERAL_SETUP: {function:$scope.openAgencySetupModal, status:"RUNNING"}
  };

  $scope.$watch("steps", () => {
    if ($scope.jobs) {
      $scope.jobs.forEach((job) => {
        job.stepName = $scope.stepName(job);
        job.stepStatus = $scope.stepStatus(job);
        $scope.isContinueDisabled(job);
      });
    }
  });
  $scope.isContinueDisabled = (job) => {
    let stepName = $scope.stepName(job);
    $scope.stepsModalFunctions[stepName]?.status !== $scope.stepStatus(job) ?  job.disabled = true :  job.disabled = false;
  }

  $scope.downloadZipFile = (job) => {
    job.downloadingFiles = true;
    importAgencyService.getAllFiles(job).then((files) => {
      const zip = new JSZip();
      files.data.reports.forEach(function (report) {
        if (report.file.data) {
          zip.file(report.fileName, report.file.data);
        }
      });

      zip
        .generateAsync({ type: "blob" })
        .then(function (content) {
          const blob = new Blob([content], { type: "application/zip" }),
            url = $window.URL || $window.webkitURL;
          return url.createObjectURL(blob);
        })
        .then((zipFile) => {
          const link = document.createElement("a");
          link.setAttribute("href", zipFile);
          link.setAttribute("download", `medflyt-${job.name}-import.zip`);
          document.body.appendChild(link);
          link.click();
          link.remove();
          job.downloadingFiles = false;
        });
    });;
  }

  $scope.openStepModal = (row) => {
    let stepName = $scope.stepName(row);
    if (stepName && stepName !== "NOT FOUND") {
      let execFunc = $scope.stepsModalFunctions[stepName].function;
      let execStatus = $scope.stepsModalFunctions[stepName].status;
      // console.log(execFunc);
      if ($scope.stepStatus(row) === execStatus) {
        execFunc(row);
      }
    }
  };

  $scope.abortJob = (job) => {
    job.status = 'ABORTED';
    const step = $scope.steps.reverse().find((step) => step.agency_import_job_id === job.id);
    importAgencyService.abortJob(job, step.id).then(() => {
      $scope.getAgenciesImportData();
    });
  }

  $scope.stepName = (row) => {
    return $scope.steps.reverse().filter((step) => step.agency_import_job_id == row.id).length > 0
      ? $scope.steps.reverse().filter((step) => step.agency_import_job_id == row.id)[0]?.name
      : "Loading...";
  };

  $scope.stepStatus = (row) => {
    return $scope.steps.reverse().filter((step) => step.agency_import_job_id == row.id).length > 0
      ? $scope.steps.reverse().filter((step) => step.agency_import_job_id == row.id)[0]?.status
      : "Loading...";
  };
};
