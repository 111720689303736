//! @ngInject
export function importAgencyFilesModalCtrl (
    $rootScope,
    $scope,
    importAgencyService,
    $uibModalInstance,
    mfModal,
    job,
    toaster,
    $window,
    stepId,
    $uibModal,
    NgTableParams
  ) {
    $scope.reportTypes = {
      DET: "DET",
      VISITS: "VISITS",
      PATIENT_DIAGNOSIS_CODE: "PATIENT_DIAGNOSIS_CODE",
      CONTRACT: "CONTRACT",
      MEMBERS: "MEMBERS",
      CHK: "CHK",
      PATIENT: "PATIENT",
      CAREGIVER: "CAREGIVER",
      BILLING_RATE: "BILLING_RATE",
      INVOICE: "INVOICE",
      AUTHORIZATION: "AUTHORIZATION",
      MASTER_WEEK: "MASTER_WEEK",
      AUTHORIZATION_ALLOCATION: "AUTHORIZATION_ALLOCATION",
      VISIT_CAREGIVER: "VISIT_CAREGIVER",
      PATIENT_DATE_RANGE: "PATIENT_DATE_RANGE",
      PATIENT_CONTRACT: "PATIENT_CONTRACT",
      CAREGIVER_RATES: "CAREGIVER_RATES",
      DISCIPLINE_RATES: "DISCIPLINE_RATES",
      VISIT_TIME_CHECK: "VISIT_TIME_CHECK",
      VISIT_ADJUSTMENTS: "VISIT_ADJUSTMENTS",
      POC: "POC",
      TIMESHEET: "TIMESHEET",
      COMPLIANCE_MED: "COMPLIANCE_MED",
      COMPLIANCE_OTHER: "COMPLIANCE_OTHER",
      COMPLIANCE_STATUS: "COMPLIANCE_STATUS",
      COMPLIANCE_CRIMINAL: "COMPLIANCE_CRIMINAL",
      COMPLIANCE_I9: "COMPLIANCE_I9",
      CAREGIVER_TRAINING_SCHOOLS: "CAREGIVER_TRAINING_SCHOOLS",
      CAREGIVER_REFERENCES: "CAREGIVER_REFERENCES",
      MD_ORDER: "MD_ORDER",
      PHYSICIAN: "PHYSICIAN",
      PHYSICIAN_PATIENT_ASSOC: "PHYSICIAN_PATIENT_ASSOC",
      MEDICATION_PROFILE: "MEDICATION_PROFILE",
      PATIENT_CERTIFICATIONS: "PATIENT_CERTIFICATIONS",
      Caregiver_Pay_Code: "Caregiver_Pay_Code",
      Contract_Service_Code: "Contract_Service_Code",
    };

    $scope.uploadMissingFiles = false;
    $scope.fileUpload = null;
    $scope.isLoading = true;
    $scope.allWithoutCaregivers = false;
    $scope.onlyCaregiversMembers = false;

    $scope.initTable = () => {
      const tableOptions = {
        count: 10,
        sorting: {
          missingColumns: "asc",
          report_type: "asc",
        },
      };

      $scope.tableData = new NgTableParams(tableOptions, {
        counts: [],
        dataset: $scope.files,
      });
    };

    $scope.getFiles = () => {
      importAgencyService.getAgencyImportFiles(job.id).then(function (res) {
        $scope.files = res.data.reports;
        $scope.initTable();
        $scope.isLoading = false;
      });
    };

    $scope.uploadFile = (file) => {
      const uploadModal = $uibModal.open({
        templateUrl: "admin/views/upload-file-modal.html",
        size: "md",
        controller: "uploadFileModalCtrl",
        resolve: {
          job: () => {
            return job;
          },
          fileId: () => {
            return file.id;
          },
          reportType: () => {
            return file.report_type;
          },
        },
      });

      uploadModal.result.then((res) => {
        if (res === "ok") {
          file.skipped = false;
          file.missingColumns = null;
        }
      });
    };

    $scope.openMissingFilesModal = () => {
      const modal = $uibModal.open({
        templateUrl: "admin/views/agency-import/agency-import-missing-files-modal.html",
        size: "md",
        controller: "importAgencyUploadMissingFilesModalCtrl",
        windowClass: "center-center",
        resolve: {
          job: () => {
            return job;
          },
          missingFiles: () => {
            return $scope.missingFiles;
          },
        },
      });

      modal.result.then((res) => {
        if (res === "ok") {
          $scope.uploadMissingFiles = false;
          $scope.errors = null;
          $scope.getFiles();
        }
      });
    };

    $scope.downloadFile = (file) => {
      console.log(file);
      importAgencyService
        .downloadImportFile(job.id, file.file_s3_location)
        .then(({ data: { url } }) => {
          window.open(url);
        })
        .catch(() =>
          toaster.pop("error", `File with name ${file.file_name} could not be downloaded`)
        );
    };

    $scope.uploadEmptyFile = (file) => {
      const modal = mfModal.create({
        subject: "Skip report",
        message: `Are you sure? removing this report means that the data won’t be imported`,
        cancelLabel: "No",
        confirmLabel: "Yes",
        variant: "danger",
        onConfirm: () => {
          $scope.createEmptyFile(file.report_type, file.id);
          file.skipped = true;
          modal.close();
        },
      });
    };

    $scope.skipAllAcceptCaregiverMembers = () => {
      $scope.isLoading = true;
      if ($scope.allWithoutCaregivers || $scope.onlyCaregiversMembers) {
        Object.values($scope.reportTypes).forEach((type) => {
          if (
            ($scope.onlyCaregiversMembers && !$scope.isCaregiverOrMembersFile(type)) ||
            ($scope.allWithoutCaregivers && $scope.isCaregiverOrMembersFile(type))
          ) {
            const file = $scope.files.find((file) => file.report_type === type);
            $scope.createEmptyFile(type, file ? file.id : null);
            if (file) {
              file.skipped = true;
            }
          }
        });
      }
      $scope.isLoading = false;
    };

    $scope.moveToNextStage = () => {
      console.log(stepId);
      $scope.isLoading = true;
      importAgencyService
        .moveToNextStage(job.id, stepId, {
          name: "MANUAL_FILE_IMPORT",
          status: "SUCCESS",
          agency_import_job_id: job.id,
        })
        .then(() => {
          $uibModalInstance.close("ok");
        })
        .catch((e) => {
          $scope.isLoading = false;
          console.log(e);
          if (e.data.errorType === "FILES_MISSING") {
            $scope.errors = e.data.error.split(",").join("\n");
            $scope.uploadMissingFiles = true;
            $scope.missingFiles = e.data.additionalData;
          } else if (e.data.errorType === "COLUMNS_MISSING") {
            $scope.errors = e.data.error.split(",").join("\n");
            $scope.files.forEach((f) => {
              const missingColumns = e.data.additionalData.filter((a) => a.type === f.report_type);
              if (missingColumns.length > 0) {
                f.missingColumns = missingColumns.map((a) => a.column).toString();
                $scope.initTable();
              }
            });
          } else {
            toaster.pop("error", "Something went wrong", e.message);
          }
        });
    };

    $scope.createEmptyFile = (reportType, fileId) => {
      importAgencyService
        .getFileTemplateColumns(reportType)
        .then((res) => {
          let columns = res.data.template[0]?.columns;
          switch (reportType) {
            case 'Caregiver_Pay_Code': {
              columns = ["Pay Code","Discipline",	"Payroll Configuration",	"Default Visit Rate",	"Default Travel Time Rate",
                	"Default In-Service Rate",	"Exclude From OT",	"Default ODT Rate",	"Default Mileage Rate",	"Status"];
                  break;
            }
            case 'Contract_Service_Code': {
              columns = ["Service Code",	"Discipline",	"Contract",	"Rate Type",	"Visit Type",	"Mutual",	"Allow Patient Shift Overlap",	"Bypass Prebilling Validations",	"Bypass Billing Review Validations",	"Auto-Schedule Service Type ID",
              	"Mileage Code",	"Export Code",	"Revenue Code",	"Taxonomy Code",	"HCPCS Code",	"Live-in Units for Export",	"Hourly Rounding"];
                  break;
            }
          }
          
          if (columns) {
            const rows = [];
            rows.push(columns);

            let csvContent = "";
            rows.forEach(function (rowArray) {
              rowArray = rowArray.map((row) => {
                switch (row) {
                  case 'Emergency  Contact2 Name': {
                    return 'Emergency Contact2 Name';
                  }
                  case 'OTPayRate': {
                    return 'OT Pay Rate';
                  }
                  case 'HolidayPayRate': {
                    return 'Holiday Pay Rate';
                  }
                }
                let humanize = /[a-z][A-Z]\w/g.exec(row);
                if (humanize && reportType !== "MEDICATION_PROFILE" && reportType !== "PHYSICIAN") {
                  return row.split(humanize[0].slice(1)).join(" " + humanize[0].slice(1))
                }
                  return row;
              });
              const row = rowArray.join(",");
              csvContent += row + "\r\n";
            });

            const blob = new Blob([csvContent], { type: "text/csv" });
            const emptyFile = new File([blob], reportType + ".csv");

            importAgencyService
              .uploadAgencyImportFile(emptyFile, job.id, fileId, reportType)
              .then(function () {
                // toaster.pop("success", "Success", "File replaced with empty one");
              })
              .catch(function (err) {
                console.log(emptyFile);
                toaster.pop("error", "Something went wrong", err.message);
              });
          } else {
            console.log(reportType + " is without template");
          }
        })
        .catch(function (err) {
          console.log(reportType);
          console.log(fileId);
          toaster.pop("error", "Something went wrong", err.message);
        });
    };

    $scope.isCaregiverOrMembersFile = (fileType) => {
      return fileType === "CAREGIVER" || fileType === "MEMBERS";
    };

    $scope.getFiles();
  };
