import _ from "lodash";

//! @ngInject
export function importAgencyComplianceModalCtrl(
  $scope,
  toaster,
  importAgencyService,
  job,
  $uibModalInstance
) {
  $scope.disabled = false;
  importAgencyService.getComplianceData(job.id).then(function (res) {
    $scope.issues = _.groupBy(res.data.issues, "section");
    $scope.items = _.groupBy(res.data.items, "section");
    $scope.answersMap = new Map(
      res.data.map.map((answer) => [
        answer.hhaxItemName,
        {
          name: answer.medflytItemName,
          isDeleted: answer.isDeleted,
          compliantResults: answer.compliantResults,
        },
      ])
    );
  });

  $scope.closeModal = (res) => {
    $uibModalInstance.close(res);
  };
  $scope.deleteRestoreItem = (key, item) => {
    item.deleted = !item.deleted;
    item.selectedOption = "";
    $scope.updateSelection(key);
  };

  $scope.updateSelection = (key) => {
    $scope.issues[key].selectedCount = $scope.issues[key].filter(
      (item) => item.selectedOption
    ).length;
  };

  $scope.setMappedMedflytValue = (issue) => {
    if ($scope.answersMap.has(issue.title)) {
      issue.selectedOption = $scope.answersMap.get(issue.title).name;
      issue.deleted = $scope.answersMap.get(issue.title).isDeleted;
      issue.selectedCompliantResults = $scope.answersMap.get(issue.title).compliantResults;
    }
  };

  $scope.moveToNextStage = () => {
    $scope.disabled = true;
    const resolutions = Object.values($scope.issues)
      .flat()
      .map((issue) => ({
        issueId: issue.id,
        hhax_item_name: issue.title ?? "",
        medflyt_item_name: issue.selectedOption ?? "",
        isDeleted: issue.deleted ?? false,
        section: issue.section,
        compliantResults: issue.selectedCompliantResults ?? null,
      }));

    importAgencyService
      .resolveCompliance(job.id, resolutions)
      .then(() => {
        $uibModalInstance.close("ok");
      })
      .catch((err) => {
        $scope.disabled = false;
        toaster.pop("error", "Please fill all the form");
      });
  };
}
