//! @ngInject
export function importNewAgencyModalCtrl ($rootScope, $scope, toaster, importAgencyService, $uibModalInstance, mfModal) {
    $scope.showHidePassword = null;
    $scope.calendarPopups = {};

    $scope.formModel = {
      importFromDate: new Date(),
      agencyName: "",
      userName: "",
      password: "",
      setupParams: {
        importFrom: ""
      },
    };

    $scope.toggleCalendarPopups = function (prop) {
      $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    };

    $scope.dateOptions = {
      startingDay: $rootScope.visitSettings.calendarStartDayOfTheWeek,
    };

    $scope.submitForm = () => {
      $scope.isSubmitted = true;
      $scope.disabled = true;
      $scope.load = true;
      if (Object.values($scope.formModel).some((model) => model === null || model === "")) {
        toaster.pop("error", "Complete all the fields in the form");
        $scope.disabled = false;
      } else {
        importAgencyService.createNewAgencyImport($scope.formModel).then(
          (res) => {
            $uibModalInstance.close("ok");
            const modal = mfModal.create({
              subject: "Files are being downloaded from HHAExchange.",
              message: `It may take a while. When done, files will appear on the dashboard`,
              hideCancelButton: true,
              confirmLabel: "OK",
              onConfirm: () => modal.close(),
            });
          },
          (err) => {
            toaster.pop("error", "Something went wrong");
            $scope.disabled = false;
          }
        );
      }
      $scope.load = false;
    };
  };
