//! @ngInject
export function importAgencyPocModalCtrl (
    $scope,
    importAgencyService,
    $uibModal,
    job,
    toaster,
    stepId,
    $uibModalInstance,
    NgTableParams,
    $filter
  ) {
    $scope.issues = [];
    $scope.showOnlyActive = true;
    $scope.showOnlyDuplicate = false;
    $scope.activeCounter = 0;
    $scope.fileUpload = null;
    $scope.fileUploaded = false;

    $scope.getItems = () => { 
      $scope.isLoading = true;
      importAgencyService.getPocIssues(job.id).then((res) => {
        $scope.originalIssues = res.data.issues;
        $scope.issues = $scope.getIssuesWithDuplications(res.data.issues);
        $scope.categories = [...new Set($scope.issues.map((issue) => issue.category))];
        $scope.filterActivePOC();
        $scope.initTable();
        $scope.isLoading = false;
      }).catch((err) => {
        toaster.pop('error', "Something went wrong", err.message);
        $scope.isLoading = false;
      });
    }


    $scope.filterActivePOC = () => {
      $scope.showOnlyActive = !$scope.showOnlyActive;
      if ($scope.showOnlyActive) {
        $scope.issues = $scope.originalIssues.filter((issue) => issue.isActive);
        $scope.activeCounter = $scope.issues.length;
        $scope.initTable();
      } else {
        $scope.showOnlyDuplicate
          ? $scope.filterDuplicatesPOC()
          : ($scope.issues = $scope.originalIssues);
          $scope.initTable();
      }
    };

    $scope.filterDuplicatesPOC = () => {
      $scope.showOnlyDuplicate = !$scope.showOnlyDuplicate;
      if ($scope.showOnlyDuplicate) {
        $scope.issues = $scope.originalIssues.filter((issue) => issue.duplicate);
        $scope.initTable();
      } else {
        $scope.showOnlyActive ? $scope.filterActivePOC() : ($scope.issues = $scope.originalIssues);
        $scope.initTable();
      }
    };

    $scope.initTable = () => {
      const tableOptions = {
        count: 10,
        page: $scope.tableData ? $scope.tableData.page() : 1,
      };

      $scope.tableData = new NgTableParams(tableOptions, {
        counts: [10, 20, 50, 100],
        dataset: $scope.issues,
      });
    };

    $scope.applyGlobalSearch = function (term) {
      var filter = { $: term };
      if ($scope.tableData) {
        angular.extend($scope.tableData.filter(), filter);
      } else {
        console.log('no table');
      }
    };

    $scope.inactivateOrRefreshItem = (issue) => {
      if (issue.isActive) {
        $scope.openInactivationModal(issue);
      } else {
        issue.isActive = true;
      }
    };

    $scope.openInactivationModal = (selectedItem) => {
      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/agency-import/agency-import-inactivate-poc-modal.html",
        size: "md",
        controller: "importAgencyInactivatePocModalCtrl",
        windowClass: "center-center agency-import-modal",
        resolve: {
          selectedItem: () => {
            return selectedItem;
          },
          itemsList: () => {
            return $scope.issues;
          },
        },
      });

      modalInstance.result.then((res) => {
        if (res) {
          let index = $scope.issues.indexOf(selectedItem);
          $scope.issues[index] = res;
        }
      });
    };

    $scope.getIssuesWithDuplications = (issues) => {
      let map = new Map();
      issues.forEach((issue) => {
        if (
          (map.has(issue.code) || map.has(issue.name)) &&
          (map.get(issue.code) || map.get(issue.name)).isActive &&
          issue.isActive
        ) {
          (map.get(issue.code) || map.get(issue.name)).duplicate = true;
          issue.duplicate = true;
        } else {
          map.set(issue.code, issue);
          map.set(issue.name, issue);
          issue.duplicate = false;
        }
      });
      return issues;
    };

    $scope.checkForEditMode = () => {
      if ($scope.issues.some((issue) => issue.editMode || issue.duplicate)) {
        $scope.issuesInvalid = true;
      } else {
        $scope.issuesInvalid = false;
      }
    };

    $scope.$watch(
      "issues",
      () => {
        $scope.issues = $scope.getIssuesWithDuplications($scope.issues);
        $scope.checkForEditMode();
        $scope.originalIssues
          ? ($scope.activeCounter = $scope.originalIssues.filter((issue) => issue.isActive).length)
          : 0;
      },
      true
    );

    $scope.exportTable = function (isTemplate) {
      $scope.loadingCSV = true;
      const rows = [];
      const titles = ["Code", "Name", "Category", "Active"];

      if (!isTemplate) {
        titles.push("Last POC Submission", "Use Times", "Source");
      }

      rows.push(titles);

      if (!isTemplate) {
        // Filter table data by global filter
        $scope.issues.forEach((dataRow) => {
          const csvRow = [];

          csvRow.push(dataRow.code);
          csvRow.push(dataRow.name);
          csvRow.push(dataRow.category);
          csvRow.push(dataRow.isActive);
          csvRow.push($filter("mfShortDate")(dataRow.lastPocSubmission));
          csvRow.push(dataRow.useTimes);
          csvRow.push(dataRow.source);
          rows.push(csvRow);
        });
      }

      let csvContent = "";
      rows.forEach(function (rowArray) {
        const row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      const universalBOM = "\uFEFF";
      const encodedUri =
        "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", isTemplate ? 'poc-master.csv' : job.name + "-poc-issues.csv");
      document.body.appendChild(link);

      link.click();
      $scope.loadingCSV = false;
    };


    $scope.createNewPocItem = () => {
      const a = {
        code: "",
        name: "",
        category: "",
        isActive: true,
        lastPocSubmission: new Date(Date.now()),
        useTimes: 1,
        source: 'Master file',
        editMode: true,
        new: true
      };
      $scope.tableData.data.push(a);
    };

    $scope.saveEditing = (issue) => {
      issue.editMode = !issue.editMode;
      if (issue.new) {
        issue.new = false;
        $scope.issues.unshift(issue);
        $scope.initTable();
      }
    }

    $scope.moveToNextStage = () => {
      if ($scope.issues.find(issue => issue.duplicate)) {
        toaster.pop("error", 'There are duplicate issues','Please fix duplications');
        $scope.initTable();
        $scope.showOnlyActive = true;
        $scope.filterDuplicatesPOC();
        return;
      }
      $scope.isLoading = true;
      const resolutions = $scope.issues.map((issue) => ({
        code: issue.code,
        name: issue.name,
        category: issue.category,
        isActive: issue.source === 'Master file' ? issue.isActive : false,
        replacedCode: issue.replacedCode ?? null,
        replacedName: issue.replacedName ?? null,
      }));

      importAgencyService.resolvePoc(job.id, resolutions).then(() => {
        importAgencyService
          .moveToNextStage(job.id, stepId, {
            name: "POC_ISSUES",
            status: "SUCCESS",
            agency_import_job_id: job.id,
          })
          .then(() => {
            $uibModalInstance.close("ok");
          })
          .catch((e) => {
            $scope.isLoading = false;
            toaster.pop("error", e.data.error || e.data);
          });
      });
    };
    $scope.$watch("fileUpload", () => {
      if ($scope.fileUpload) {
        $scope.isLoading = true;
        var fileType = $scope.fileUpload.name.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType.toLowerCase();
        if (fileType === "csv") {
          importAgencyService
            .uploadPocMasterFile(job.id, $scope.fileUpload)
            .then(() => {
              toaster.pop("success", "Success", "File Uploaded");
              $scope.fileUploaded = true;
              $scope.getItems();
            })
            .catch((err) => {
              $scope.isLoading = false;
              toaster.pop("error", "Something went wrong", err.message);
            });

          $scope.isLoading = false;
        } else {
          toaster.pop(
            "warning",
            "Invalid file",
            "Please upload csv file"
          );
          $scope.fileUpload = null;
          $scope.isLoading = false;
          $scope.fileUploaded = false;
        }
      }
    });
  };
