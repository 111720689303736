//! @ngInject
export function uploadFileModalCtrl ($scope, $uibModalInstance, toaster, importAgencyService, job, fileId, reportType) {

    $scope.reportType = reportType;
    $scope.fileUpload = null;
    $scope.isLoading = false;
    $scope.$watch("fileUpload", () => {
      if ($scope.fileUpload) {
        $scope.isLoading = true;
        var fileType = $scope.fileUpload.name.split(".");
        fileType = fileType[fileType.length - 1];
        fileType = fileType.toLowerCase();
        if (fileType === "csv") {
          importAgencyService
            .uploadAgencyImportFile($scope.fileUpload, job.id, fileId, reportType)
            .then(function () {
              $uibModalInstance.close('ok');
              toaster.pop("success", "Success", "File Uploaded");
            }).catch(function (err) {
              toaster.pop("error", "Invalid file", err.message || err.data.details[0]);
              $uibModalInstance.close();
            });
        } else {
          toaster.pop("warning", "Invalid file", "Please upload file with same name & type as the old one");
          $scope.fileUpload = null;
          $scope.isLoading = false;
        }
      }
    });
  };
