import { LocalDate } from "js-joda";

//! @ngInject
export function importAgencyOvelappingRatesModalCtrl ($scope, importAgencyService, $uibModal, job, stepId, $uibModalInstance, $filter) {
    $scope.resolutionsMap = new Map();
    $scope.groupsMap = new Map();
    $scope.selectedText = "";
    $scope.selectedCounties = [];
    importAgencyService.getOverlappingRates(job.id).then((overlappingRates) => {
      $scope.calculateRatesGroups(overlappingRates);
      $scope.rates = Array.from($scope.groupsMap.values()).flatMap((x) => x);
    });

    $scope.openLocationGroupSelectionModal = (rate) => {
      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/agency-import/import-agency-select-location-group-modal.html",
        size: "md",
        controller: "importAgencySelectLocationGroupModalCtrl",
        resolve: {
          job: () => {
            return job;
          },
          contractName: () => {
            return rate.contractName;
          },
        },
      });

      modalInstance.result.then((res) => {
        if (res.selectedCounties && res.selectedGroups) {
          rate.selectedText = "Specific location groups";
          rate.selectedLocationGroups = res.selectedGroups.toString();
          $scope.resolutionsMap.set(rate.id, {
            type: "SpecificLocationGroup",
            selectedLocationGroupsIds: res.selectedCounties,
          });
        }
      });
    };

    $scope.exportTable = function () {
      $scope.loadingCSV = true;
      const rows = [];
      const titles = [
          'contract_name',
          'original_service_code',
          'new_service_code',
          'from',
          'to',
          'rate',
          'selected_action'
      ];
      rows.push(titles);

      // Filter table data by global filter
      $scope.rates.forEach(dataRow => {
          const csvRow = [];

          csvRow.push(dataRow.contractName);
          csvRow.push(dataRow.OriginalServiceCode);
          csvRow.push(dataRow.newServiceCode);
          csvRow.push($filter("mfShortDate")(dataRow.from));
          csvRow.push($filter("mfShortDate")(dataRow.to));
          csvRow.push(dataRow.rate);
          csvRow.push((dataRow.selectedText ?? '') + (dataRow.selectedLocationGroups ? ": " + dataRow.selectedLocationGroups.replace(/\n|,/g," ") : ''));
          

          rows.push(csvRow);
      });

      let csvContent = "";
      rows.forEach(function (rowArray) {
        const row = rowArray.join(",");
        csvContent += row + "\r\n";
      });

      const universalBOM = "\uFEFF";
      const encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", job.name + "-overlaping-rates.csv");
      document.body.appendChild(link);

      link.click();
      $scope.loadingCSV = false;
  };

    $scope.onOptionSelected = (rate, option) => {
      rate.selectedText = option;
      $scope.resolutionsMap.set(rate.id, { type: option });
    };

    $scope.moveToNextStage = () => {
      const resolutions = Array.from($scope.resolutionsMap, (resolution) => ({
        id: resolution[0],
        resolution: { type: resolution[1].type },
        selectedLocationGroupsIds: resolution[1]?.selectedLocationGroupsIds,
      }));
      importAgencyService.resolveOverlappingRates(job.id, resolutions).then(() => {
        importAgencyService.moveToNextStage(job.id, stepId,{name: "OVERLAPPING_RATES_SETUP",status: "SUCCESS",agency_import_job_id: job.id}).then(() =>{
          $uibModalInstance.close("ok");
        }).catch((e) => {
          $scope.errors = e.data.error;
        });
      });
    };

    $scope.isRateInDatesIntersection = (rate) => {
      const fromDate = LocalDate.parse(rate.from);
      const toDate = LocalDate.parse(rate.to);
      return $scope.groupsMap.get(rate.contractName + rate.newServiceCode).some((newRate) => {
        return (
          LocalDate.parse(newRate.from).isBefore(fromDate) ||
          LocalDate.parse(newRate.to).isAfter(toDate) || 
          LocalDate.parse(newRate.from).isEqual(fromDate) || 
          LocalDate.parse(newRate.to).isEqual(toDate)
        );
      });
    };

    $scope.calculateRatesGroups = (overlappingRates) => {
      overlappingRates.data.rates.forEach((rate) => {
        if ($scope.groupsMap.has(rate.contractName + rate.newServiceCode)) {
          if ($scope.isRateInDatesIntersection(rate)) {
            rate.group = $scope.groupsMap.get(rate.contractName + rate.newServiceCode)[0].group;
            $scope.groupsMap.get(rate.contractName + rate.newServiceCode).push(rate);
          } else {
            rate.group = $scope.groupsMap.size + 1;
            $scope.groupsMap.set(
              rate.contractName + rate.newServiceCode + $scope.groupsMap.size + 1,
              [rate]
            );
          }
        } else {
          rate.group = $scope.groupsMap.size + 1;
          $scope.groupsMap.set(rate.contractName + rate.newServiceCode, [rate]);
        }
      });
    };
  };
