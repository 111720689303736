import _ from "lodash";

//! @ngInject
export function importAgencyMissingRatesModalCtrl ($scope, toaster, importAgencyService, $uibModal,job,stepId, $uibModalInstance) {
  $scope.uploadedNewFile = false;
  $scope.isLoading = true;
    importAgencyService.getMissingRates(job.id).then( function (res) { 
      $scope.contractRates = res.data.contract.map(rate => rate.contract_name).toString();
      $scope.billingFileKey = res.data.fileKey;
      $scope.billingFileId = res.data.fileId;
        $scope.visitsRates = _.groupBy(res.data.visits, "contractName");
        $scope.groupedVisitRates = Object.entries($scope.visitsRates).map(rate => ({contract: rate[0], serviceCodes:_.groupBy(rate[1], 'serviceCode')}));
        $scope.isLoading = false;
       });

       $scope.downloadRatesFile = () => {
        importAgencyService.downloadImportFile(job.id, $scope.billingFileKey).then(({ data: { url } }) => {
            window.open(url);
          })
          .catch(() => toaster.pop("error", `File could not be downloaded`));
       }

       $scope.uploadFile = () => {
        const modalInstance = $uibModal.open({
           templateUrl: "admin/views/upload-file-modal.html",
           size: "md",
           controller: "uploadFileModalCtrl",
           resolve: {
             job:() => {return job},
             fileId: () => {return $scope.billingFileId},
             reportType: () => {return 'BILLING_RATE'}
           },
         });

         modalInstance.result.then((res) => {
          if (res === "ok") {
            $scope.uploadedNewFile = true;
          }
        });
     }

     $scope.moveToNextStage = () => {
       importAgencyService
         .moveToNextStage(job.id, stepId, {
           name: "RATES_SETUP",
           status: "SUCCESS",
           agency_import_job_id: job.id,
           extraData: { isUploadedNewFile: $scope.uploadedNewFile },
         })
         .then(() => {
           $uibModalInstance.close("ok");
         })
         .catch((e) => {
           $scope.errors = e.data.error;
         });
     };
};